<template>
    <div>
        <div class="contact-section">
            <b-col
                offset-lg="2"
                lg="8"
            >
                <div class="contact-text">
                    <b-img
                        fluid
                        :src="require('@/assets/images/home/contact-icon.png')"
                        alt="contact-icon"
                    />
                    <h5>We bring together all of your data, so you can conveniently manage <b>your business finances from one place</b></h5>
                </div>
            </b-col>
        </div>
        <b-row class="contact-bottom m-0 p-0">
            <b-col
                class="contact-text"
                offset-lg="1"
                lg="5"
                md="12"
            >
                <h6>Join us and try our software for FREE</h6>
                <p>Aliquam erat volutpat. Maecenas nibh purus, sagittis at molestie vitae, pretium vitae nisl.</p>
            </b-col>
            <b-col
                class="contact-button"
                lg="5"
                md="12"
            >
                <b-form-input
                    class="contact-input"
                    placeholder="Enter your email address"
                />
                <b-button class="btn-contact">
                    Try Freemium Now
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BButton, BImg, BFormInput } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BImg,
        BFormInput,
    },
}
</script>