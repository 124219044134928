<template>
    <div class="banner-about">
        <div class="banner-bg-right-1"></div>
        <b-col
            class="banner-text"
            md="12"
        >
            <div class="banner">
                <h1>What we’re doing</h1>
                <div class="text-bottom">
                    <p class="rectangle"></p>
                    <p class="rectangle"></p>
                </div>
            </div>
        </b-col>
    </div>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BImg,
    },
}
</script>