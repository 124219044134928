<template>
    <div class="about-content">
        <b-col
            class="about-text"
            offset-lg="2"
            lg="8"
            md="12"
        >
            <h2>CostCtrl is a platform designed for business owners, managers and analysts to discover profitability and productivity within their business.</h2>
            <p>We give you the tools to build costing models from your own business data in a straightforward and approachable manner, to become an indispensable tool in running a business.</p>
            <p>Modern businesses generate mountains of data, but their financial information is still anchored in traditional accounting practices and tends to be very high level. Using the wealth of operational data, we can identify the net profitability of customers, products, business units, regions and more. Owners and managers have always had an intuition for which parts of their business are driving profit, and where resources are sunk for very little return.</p>
            <p>CostCtrl’s modern modelling platform accurately quantifies this to enable truly qualified decision making, whether that’s a pricing decision with a customer or a long term market strategy.</p>
        </b-col>
        <b-col
            class="about-img"
            offset-lg="1"
            lg="10"
        >
            <b-img
                fluid
                :src="require('@/assets/images/about/grafics.png')"
                alt="contact-icon"
            />
        </b-col>
        <b-col
            class="about-text"
            offset-lg="2"
            lg="8"
            md="12"
        >
            <p>Within the business, departments and business units consume time and cost to do their role, both operationally and in support of one another. Only by building a way to measure this can we understand capacity and productivity, or accurately distribute shared services costs among cost centres. Model building on Cost Ctrl’s platform enables this in a straightforward and approachable manner, and once built, automates the process.</p>
            <p>Historically, sophisticated costing models have only been attainable by multi-national companies with complex systems and large budgets for expensive tools and consultants. Cost Ctrl strongly believe that access to the insights from our platform should be indispensable for any business with a system and some data, and a drive to grow their profits. The data generated by modern systems is wasted if not used to create financial insights, and we’re determined to make this accessible to all business.</p>
            <p>We built this product on a background of 35 combined years of designing, building and maintaining complex models on difficult systems. The ROI in these complex projects paid for themselves multiple times over, but the barriers to beginning a project made them difficult to begin. Everyone was identifying big profit potential, but forging their own path from scratch even though many business fundamentals were extremely similar!</p>
        </b-col>
        <b-row class="m-0">
            <b-col
                class="about-icons"
                offset-lg="1"
                lg="3"
            >
                <b-img
                    fluid
                    :src="require('@/assets/images/about/saas.png')"
                    alt="contact-icon"
                />
                <p>CostCtrl was designed to make these projects approachable and low risk, by adopting a SaaS model and leveraging industry templates and experience.</p>
            </b-col>
            <b-col
                class="about-icons"
                lg="4"
            >
                <b-img
                    fluid
                    :src="require('@/assets/images/about/saas.png')"
                    alt="contact-icon"
                />
                <p>CostCtrl was designed to make these projects approachable and low risk, by adopting a SaaS model and leveraging industry templates and experience.</p>
            </b-col>
            <b-col
                class="about-icons"
                lg="3"
            >
                <b-img
                    fluid
                    :src="require('@/assets/images/about/saas.png')"
                    alt="contact-icon"
                />
                <p>CostCtrl was designed to make these projects approachable and low risk, by adopting a SaaS model and leveraging industry templates and experience.</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BButton, BImg } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BImg,
    },
}
</script>