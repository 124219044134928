<template>
  <div>
    <banner-about />
    <content-about />
    <contact-about />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import bannerAbout from './bannerAbout.vue'
import contentAbout from './contentAbout.vue'
import contactAbout from './contactAbout.vue'


export default {
  components: {
    BRow,
    BCol,
    bannerAbout,
    contentAbout,
    contactAbout,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-about.scss';
</style>
